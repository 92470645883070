<template>
    <div>
        <a name="our_service">
            <h2 class="fs-1">บริการของเรา</h2>
        </a>
        <div class="row">
            <div class="col-6">
                <img src="@/assets/1.png" class="img-fluid"/>
            </div>
            <div class="col-6">
                <img src="@/assets/15.png" class="img-fluid"/>
            </div>
            <div class="col-6">
                <img src="@/assets/2.png" class="img-fluid"/>
            </div>
            <div class="col-6">
                <img src="@/assets/17.png" class="img-fluid"/>
            </div>
            <div class="col-6">
                <img src="@/assets/18.png" class="img-fluid"/>
            </div>
            <div class="col-6">
                <img src="@/assets/19.png" class="img-fluid"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RecommendFuntion",
    data() {
        return {
            loaded: false,
        };
    },
    mounted() {
    
    },
    watch: {

    },
}
</script>