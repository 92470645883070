<template>
    <div class="row g-0">
        <a name="warehouse"></a>
        <div class="col-12">
            <img src="@/assets/2222.png" class="img-fluid" />
        </div>
    </div>
</template>

<script>
export default {
    name: "WareHouse",
    data() {
        return {
            loaded: false,
        };
    },
    mounted() {

    },
    watch: {

    },
}
</script>