<template>
    <div class="mt-100px bg-light">
        <div class="container">
            <HeaderBar></HeaderBar>
            <SlideImage class="mt-3"></SlideImage>
            <hr class="my-5" />
            <VideoPanel class="mt-5"></VideoPanel>
            <hr class="my-5" />
            <RecommendFuntion class="mt-5"></RecommendFuntion>
            <hr class="my-5" />
            <WareHouse class="mt-5"></WareHouse>
            <hr class="my-5" />
            <SupportTeam class="mt-5"></SupportTeam>
            <hr class="my-5" />
            <TransportWay class="mt-5"></TransportWay>
        </div>
        <ContactUs class="my-5"></ContactUs>
    </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar.vue';
import SlideImage from '@/components/SlideImage.vue';
import RecommendFuntion from '@/components/RecommendFuntion.vue';
import VideoPanel from '@/components/VideoPanel.vue';
import WareHouse from '@/components/WareHouse.vue';
import SupportTeam from '@/components/SupportTeam.vue';
import TransportWay from '@/components/TransportWay.vue';
import ContactUs from '@/components/ContactUs.vue';

export default {
    name: "MainPage",
    components: {
        HeaderBar,
        SlideImage,
        RecommendFuntion,
        VideoPanel,
        WareHouse,
        SupportTeam,
        TransportWay,
        ContactUs,
    },
    mounted() {
        const scripts = [
            "https://cdn.chaty.app/pixel.js?id=uD6KM0Qa",
            
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        }); 
    }
}
</script>
