<template>
    <div class="row">
        <div class="col-12 youtube-video-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/3O-hP6eFiZY?si=kO4sV1RlDfHne0m2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
    </div>
</template>

<script>
// import { videoPlayer } from 'vue-video-player';
// import 'video.js/dist/video-js.css';

export default {
    name: "VideoPanel",
    components: {
        // videoPlayer,
    },
    data() {
        return {
            loaded: false,
            playerOptions: {
                start: 0,
                playsinline: false,
                muted: true,
                language: 'en',
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                sources: [{
                    type: "video/mp4",
                    src: "https://dodee-thaicargo.com/vllo.MP4"
                }],
                poster: "",
            }
        };
    },
    mounted() {

    },
    watch: {

    },
    methods: {
        onPlayerPlay(player) {
            console.log(player);
        },
        playerStateChanged(playerCurrentState) {
            console.log(playerCurrentState);
        },
        playerReadied(player) {
            console.log('the player is readied', player);
        }
    }
}
</script>
<style>
.video-js {
    width: 100% !important;
}

.youtube-video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.youtube-video-container::after {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.youtube-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>