<template>
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid">
            <a class="navbar-brand" href="#">
                <h1 class="fs-1 text-start " ref="title_name">
                    ดูดีไทยคาร์โก้
                </h1>
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="true" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="ms-5 collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" href="#our_service">บริการของเรา</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#warehouse">โกดังสินค้า</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#contact_us">ติดต่อเรา</a>
                    </li>

                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "NavBar",
    data() {
        return {

        };
    },
}
</script>